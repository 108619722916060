export function setValuesForm(form: any, items: any) {
  let keys = form.value;
  for (let item of items) {
    if (item.name in keys) {
      form.get(item.name).setValue(item.value);
    }
  }
}

export function cleanText(txt) {
  const chars = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    ñ: "n",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    À: "A",
    È: "E",
    Ì: "I",
    Ò: "O",
    Ù: "U",
    Ñ: "N",
  };
  const expr = /[áàéèíìóòúùñ]/gi;
  const res = txt.replace(expr, function (e) {
    return chars[e];
  });
  return res;
}
